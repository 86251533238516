
/* Add your profile image here */
.Photo{
    background-image: url("../asset/mypic.png");
}
/* Projects Photo */
.project1{
    background-image: url('../asset/nextdayflyer.png');
    cursor: none;    
}
.project2{
    background-image: url('../asset/flipkarimg.png');
    cursor: none;
}
.project3{
    background-image: url('../asset/nordstromimg.png');
    cursor: none;
}
.project4{
    background-image: url('../asset/flipkarimg.png');
    cursor: none;
}
.project5{
    background-image: url('../asset/MentalHealth.png');
    cursor: none;
}
.project6{
    background-image: url('https://via.placeholder.com/400x600.png?text=Project+six');
    cursor: none;
}
/* Miscelaneous Activities photo */
.misc1{
    background-image: url('https://via.placeholder.com/400x600.png?text=Miscellaneous+one');
    cursor: none;
}
.misc2{
    background-image: url('https://via.placeholder.com/400x600.png?text=Miscellaneous+two');
    cursor: none;
}
.misc3{
    background-image: url('https://via.placeholder.com/400x600.png?text=Miscellaneous+three');
    cursor: none;
}